<template>
  <small-layout
    ><div>
      <button @click="paramSupplierId = null">Show all comments</button>
      <h1>Партнеры</h1>
      <ul>
        <li>
          <router-link to="/suppliers/cover">Supplier Cover table</router-link>
        </li>
        <li>
          <router-link to="/attractions/cover">Attraction Cover table</router-link>
        </li>
        <li>
          <router-link to="/suppliers/coverage">Coverage</router-link>
        </li>

        <li>
          <router-link to="/suppliers/schedule_list">{{ $t("attraction.schedule_list_title") }}</router-link>
        </li>
        <li>
          <router-link to="/suppliers/act_table">Payment Table</router-link>
        </li>
        <li>
          <router-link v-if="country === 'by'" :to="{ name: 'by_supplier_create', params: { country } }"
            >Добавить
          </router-link>
          <router-link v-else :to="{ name: 'default_supplier_create', params: { country } }">Добавить</router-link>
        </li>
      </ul>
      <form v-if="columnFormToggler" id="columnFormToggler" @submit.prevent="addColumnForm">
        <div>
          <label for="column_name">Имя столбца</label>
          <input id="column_name" type="text" />
        </div>
        <div>
          <label for="column_key">Поле данных столбца</label>
          <select id="column_key">
            <option value="attraction_list_extended">Attraction list with costs</option>
            <option value="android_build">Build Android NFC Scanner</option>
            <option value="pwa_build">Build QR Scanner PWA</option>
            <option value="new_pwa_build">Build QR Scanner PWA (Request)</option>
            <option value="name">Name</option>
            <option value="lat">lat</option>
            <option value="lng">lng</option>
            <option value="general_information">For client: about them</option>
            <option value="additional">For client: rules and conditions</option>
            <option value="rules_r">Rules R</option>
            <option value="rules_s">Rules S</option>
            <option value="rules_g">Rules G</option>
            <option value="rules_p">Rules P</option>
            <option value="money_prev_month">Money Prev Month</option>
            <option value="visits_prev_month">Visits Prev Month</option>
            <option value="money_this_month">Money This Month</option>
            <option value="visits_this_month">Visits This Month</option>
            <option value="administrative_info">Administrative Contacts</option>
            <option value="tag_list">Теги (для карты)</option>
            <option value="icon">Иконка</option>
            <option value="opening_hours_text">Schedule</option>
            <option value="city">City</option>
            <option value="district">District</option>
            <option value="address">Address</option>
            <option value="contact_phone">Контактный Телефон для посетителей</option>
            <option value="scanning_status">Status</option>
            <option value="negotiation">Согласованные моменты, особенность договора с ними, помимо цен</option>
            <option value="partner_status">Текущий статус партнера</option>
            <option value="number_agreement">number_agreement</option>
            <option value="number_agreement_super">number_agreement_super</option>
            <option value="registered_name">Legal Name</option>
            <option value="reason_outdated">Какой информации не хватает</option>
            <option value="user_name">Кем Обновлено</option>
            <option value="administration_status">Актуальность Информации</option>
            <option value="appendix">Appendix with date</option>
            <option value="hardware_device">Given Device</option>
            <option value="hardware_device_number">Given Device phone number (*147#)</option>
            <option value="hardware_device_details">Given Hardware</option>
            <option value="hardware_device_internet_left">Given Hardware Network Left</option>
            <option value="accepted_visit_at">Last accepted visit</option>
            <option value="last_visit_at">Last visit</option>
            <option value="accnum">Recipient Account</option>
            <option value="bank_name">Recipient Bank</option>
            <option value="bik">Recipient BIK</option>
            <option value="aemail">Accountant Email</option>
            <option value="postaddress">Post Address</option>
            <option value="legal_address">Recipient Legal Address</option>
            <option value="legal_contact_phone">Recipient Legal Contact Phone</option>
            <option value="unp">Recipient UNP</option>
            <option value="vat_rate">Recipient VAT rate</option>
            <option value="client_rules">Rules (link)</option>
            <option value="official_representative">Signed by Official Representative</option>
            <option value="power_of_attorney_representative">Signed with Power of attorney</option>
            <option value="website">Site</option>
            <option value="allsports_contact">Who signed contract from AllSports</option>
            <option value="support_phone">Manager Phone who support them</option>
            <option value="payment_details">Payment Details</option>
            <option value="visit_autoaccept">Auto-confirmation of visits</option>
          </select>
        </div>
        <button type="submit">Сохранить</button>
      </form>
      <button type="button" @click="columnFormToggler = !columnFormToggler">Форма добавления столбцов</button>
      <button type="button" @click="clearColumnsAndRefresh">Вернуть Столбцы по умолчанию</button>
      <button type="button" @click="filterToggler = !filterToggler">Фильтр поставщиков</button>
      <supplier-index-filter
        class="formFilter"
        v-if="filterToggler"
        @hide="filterToggler = false"
      ></supplier-index-filter>
      <table>
        <TableHeader
          :additionalColumns="additionalColumns"
          :columns="columns"
          :orderKey="orderKey"
          @remove:headerItem="removeHeaderColumn"
          @update:orderKey="sortBy"
        />
        <tbody>
          <tr v-for="entry in filteredSource">
            <td v-for="column in columns">
              <span v-if="column.key === 'registered_name'">
                <router-link :to="{ name: 'search_visit_supplier', params: { supplier_id: entry.id } }">{{
                  entry.registered_name
                }}</router-link>
              </span>
              <span v-else-if="column.key == 'name'">
                <router-link v-if="entry.id" :to="{ name: 'supplier_view', params: { supplier: entry.id } }">{{
                  entry.name
                }}</router-link>
              </span>
              <span v-else-if="column.key == 'scanning_status'" :class="getClassStatus(entry)">{{
                getTextStatus(entry)
              }}</span>
              <span
                v-else-if="column.key == 'accepted_visit_at' || column.key == 'last_visit_at'"
                :class="getAcceptedVisitAtClass(entry, column.key)"
                >{{ getAcceptedVisitAt(entry, column.key) }}</span
              >
              <span v-else-if="column.key == 'attraction_list_extended'">{{ getJsonObject(entry.attractions) }}</span>
              <span v-else>{{ entry[column.key] }}</span>
            </td>
            <td>
              <router-link v-if="country === 'by'" :to="{ name: 'by_supplier_update', params: { supplier: entry.id } }"
                >&#9998;</router-link
              >
              <router-link v-else :to="{ name: 'default_supplier_update', params: { supplier: entry.id, country } }"
                >&#9998;</router-link
              >
              <button @click="fireDeletingSupplier(entry)">Delete</button>
              <button @click="paramSupplierId = entry.id">&#8505;</button>
              <router-link
                v-if="isIntegrationSupplier(entry)"
                :to="{ name: ROUTES_NAMES.suppliers.integrations.manage, params: { supplierId: entry.id } }"
              >
                Manage integration
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div></small-layout
  >
</template>

<script>
import SortMixin from "../../mixins/SortMixin";
import { mapActions, mapGetters } from "vuex";
import SupplierIndexFilter from "./SupplierIndexFilter";
import TableHeader from "@/components/tables/TableHeader";

import { ROUTES_NAMES } from "@/router";
import { SUPPLIER_INTEGRATION_STATUS } from "@/config/integrations";

const date = new Date();
date.setMonth(date.getMonth() - 1);
function addZ(n) {
  return (n < 10 ? "0" : "") + n;
}
const month_ago =
  date.getFullYear() +
  "-" +
  addZ(date.getMonth() + 1) +
  "-" +
  addZ(date.getDate()) +
  " " +
  addZ(date.getHours()) +
  ":" +
  addZ(date.getMinutes()) +
  ":" +
  addZ(date.getSeconds());
export default {
  components: {
    SupplierIndexFilter,
    TableHeader,
  },
  mixins: [SortMixin],
  data() {
    return {
      ROUTES_NAMES,

      columnFormToggler: false,
      paramSupplierId: null,
      filterToggler: false,

      additionalColumns: ["Действия"],
    };
  },
  computed: {
    ...mapGetters({
      source: "suppliers/supplier_list",
      country: "auth/country",
    }),
    filteredSource() {
      if (this.columns.length === 0) {
        return [];
      }

      const orderKey = this.orderKey;
      const index = this.columns.findIndex((column) => column.key === orderKey);
      let order = -1;
      if (index >= 0) {
        order = this.columns[index].order;
      }
      let data = this.source;
      if (orderKey && index !== -1) {
        this.columns[index].order = order;
        data = data.slice().sort(function (a, b) {
          a = a[orderKey];
          b = b[orderKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      if (index === -1) {
        console.log(`${index} cannot be -1. We are looking for ${orderKey} in our columns.`);
      }
      return data;
    },
  },
  methods: {
    ...mapActions({
      fetchSuppliers: "suppliers/load_supplier_list",
      deleteSupplier: "suppliers/delete_supplier",
    }),
    createColumns() {
      const columns = this.getColumns();
      if (!columns || !columns.length) {
        return this.clearColumns();
      }
      return columns;
    },
    getColumns() {
      try {
        let data = JSON.parse(localStorage.getItem("journal_supplier_index_columns"));
        return data.filter((item) => {
          return item && item.key && item.name;
        });
      } catch (e) {
        return [];
      }
    },
    clearColumns() {
      this.saveColumns([
        { key: "id", name: "ID", order: 1 },
        { key: "name", name: "Название", order: 1 },
        { key: "money_prev_month", name: "Money Prev Month", order: 1 },
        { key: "visits_prev_month", name: "Visits Prev Month", order: 1 },
        { key: "money_this_month", name: "Money This Month", order: 1 },
        { key: "visits_this_month", name: "Visits This Month", order: 1 },
        { key: "registered_name", name: "Registered As", order: 1 },
        { key: "address", name: "Address", order: 1 },
        { key: "contact_phone", name: "Contact Phone", order: 1 },
        { key: "website", name: "Website", order: 1 },
        { key: "scanning_status", name: "Tech Status", order: 1 },
      ]);
      return this.getColumns();
    },
    clearColumnsAndRefresh() {
      this.columns = this.clearColumns();
    },
    saveColumns(columns) {
      localStorage.setItem("journal_supplier_index_columns", JSON.stringify(columns));
    },
    addColumn(column) {
      let columns = this.getColumns();
      columns.push(column);
      this.saveColumns(columns);
      this.columns = columns;
    },
    addColumnForm(e) {
      let key = e.target.column_key.value;
      let name = "";
      if (e.target.column_key.selectedOptions.length) {
        name = e.target.column_key.selectedOptions[0].text;
      }
      if (e.target.column_name.value) {
        name = e.target.column_name.value;
      }
      this.addColumn({ key: key, name: name, order: 1 });
      this.columns = this.getColumns();
    },
    removeHeaderColumn(itemKey) {
      let columns = this.getColumns();
      columns = columns.filter((column) => column.key !== itemKey);
      this.saveColumns(columns);
      this.columns = columns;
    },
    getClassStatus(entry) {
      if ((entry.scanning_status & 1) !== 1) {
        return "is_filled";
      }
      if ((entry.scanning_status & 3) === 3) {
        return "up_to_date";
      }
      return "warning";
    },
    getJsonObject(value) {
      return JSON.stringify(value);
    },
    getTextStatus(entry) {
      if ((entry.scanning_status & 15) === 15) {
        return "Онлайн";
      }
      if ((entry.scanning_status & 13) === 13) {
        return "Офлайн (NFC only - QR OFF)";
      }
      if ((entry.scanning_status & 11) === 11) {
        return "Онлайн (NFC only - QR OFF)";
      }
      if ((entry.scanning_status & 7) === 7) {
        return "Онлайн (QR only)";
      }
      if ((entry.scanning_status & 5) === 5) {
        return "Офлайн (QR only)";
      }
      if ((entry.scanning_status & 1) !== 1) {
        return "Незаполненный";
      }
      return "Заполненный";
    },
    getAcceptedVisitAtClass(entry, fieldname) {
      if (entry[fieldname] < month_ago) {
        return "is_filled";
      }
      return "up_to_date";
    },
    getAcceptedVisitAt(entry, fieldname) {
      return entry[fieldname];
    },
    async fireDeletingSupplier(entry) {
      if (confirm("Are you sure?")) {
        await this.deleteSupplier(entry);
      }
    },
    isIntegrationSupplier(entry) {
      return entry.access_mode === SUPPLIER_INTEGRATION_STATUS;
    },
  },
  async mounted() {
    await this.fetchSuppliers({ country: this.country });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

@include table-hoverable;

.up_to_date {
  background: #d2fced;
}
.is_filled {
  background: #e7b6b6;
}
.warning {
  background: yellow;
}
.remove_button {
  float: right;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
  display: inline;
  position: relative;
  vertical-align: text-top;
}
.formFilter {
  position: fixed;
  left: 20%;
  top: 20%;
  background-color: whitesmoke;
}

.arrow_sort {
  opacity: 0.5;
}

th {
  &.active {
    text-decoration: underline;
  }
}
</style>
